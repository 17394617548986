
        import * as module0 from '$components/advisers/adviser_map/adviser_map_controller.js';import * as module1 from '$components/advisers/adviser_search_bar/adviser_search_bar_controller.js';import * as module2 from '$components/advisers/adviser_widget/adviser_widget_controller.js';import * as module3 from '$components/individual/prismic_edit_button/prismic_edit_button_controller.js';import * as module4 from '$components/individual/search_bar/search_bar_controller.js';import * as module5 from '$components/individual/support_widget/support_widget_controller.js';import * as module6 from '$components/shared/bento_grid/bento_grid_controller.js';import * as module7 from '$components/shared/carousel/carousel_controller.js';import * as module8 from '$components/shared/comparison_table/comparison_table_controller.js';import * as module9 from '$components/shared/dropdown/dropdown_controller.js';import * as module10 from '$components/shared/expandable_list/expandable_list_controller.js';import * as module11 from '$components/shared/feature_list/feature_list_controller.js';import * as module12 from '$components/shared/featured_pages/featured_pages_controller.js';import * as module13 from '$components/shared/highlight/highlight_controller.js';import * as module14 from '$components/shared/intro/intro_controller.js';import * as module15 from '$components/shared/live_sessions/live_sessions_controller.js';import * as module16 from '$components/shared/price_cards_container/price_cards_container_controller.js';import * as module17 from '$components/shared/quick_page_switcher/quick_page_switcher_controller.js';import * as module18 from '$components/shared/reviews/reviews_controller.js';import * as module19 from '$components/shared/sign_up_for_moneybird/sign_up_for_moneybird_controller.js';import * as module20 from '$components/shared/vat_calculator/vat_calculator_controller.js'
        const modules = {
            "advisers/adviser_map/adviser_map_controller.js": module0,
            "advisers/adviser_search_bar/adviser_search_bar_controller.js": module1,
            "advisers/adviser_widget/adviser_widget_controller.js": module2,
            "individual/prismic_edit_button/prismic_edit_button_controller.js": module3,
            "individual/search_bar/search_bar_controller.js": module4,
            "individual/support_widget/support_widget_controller.js": module5,
            "shared/bento_grid/bento_grid_controller.js": module6,
            "shared/carousel/carousel_controller.js": module7,
            "shared/comparison_table/comparison_table_controller.js": module8,
            "shared/dropdown/dropdown_controller.js": module9,
            "shared/expandable_list/expandable_list_controller.js": module10,
            "shared/feature_list/feature_list_controller.js": module11,
            "shared/featured_pages/featured_pages_controller.js": module12,
            "shared/highlight/highlight_controller.js": module13,
            "shared/intro/intro_controller.js": module14,
            "shared/live_sessions/live_sessions_controller.js": module15,
            "shared/price_cards_container/price_cards_container_controller.js": module16,
            "shared/quick_page_switcher/quick_page_switcher_controller.js": module17,
            "shared/reviews/reviews_controller.js": module18,
            "shared/sign_up_for_moneybird/sign_up_for_moneybird_controller.js": module19,
            "shared/vat_calculator/vat_calculator_controller.js": module20,
        };
        export default modules;
      